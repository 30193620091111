var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"flag":_vm.flag}}),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"advantage"},[_vm._m(3),_c('div',{staticClass:"advantage-content"},_vm._l((_vm.advantages),function(item){return _c('div',{staticClass:"advantage-box"},[_c('div',{staticClass:"box-icon"},[_c('img',{attrs:{"src":item.image}})]),_c('div',{staticClass:"box-intro"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('br'),_c('span',{staticClass:"intro"},[_vm._v(_vm._s(item.subTitle))])])])}),0)]),_c('div',{staticClass:"scenes"},[_vm._m(4),_c('div',{staticClass:"scenes-content"},[_c('div',{staticClass:"scenes-content-l"},_vm._l((_vm.scenes),function(item){return _c('div',{staticClass:"scenes-box"},[_c('div',{staticClass:"box-content"},[_c('span',{staticClass:"scenes-title"},[_vm._v(_vm._s(item.title))]),_c('br'),_c('span',{staticClass:"scenes-text"},[_vm._v(" "+_vm._s(item.subTitle)+" ")])])])}),0),_vm._m(5)])]),_c('div',{staticClass:"industry"},[_vm._m(6),_c('div',{staticClass:"industry-content"},_vm._l((_vm.industry),function(item){return _c('div',{staticClass:"industry-box"},[(item.image!=null && item.image !='')?_c('div',{staticClass:"box-icon"},[_c('img',{attrs:{"src":item.image}})]):_vm._e(),_c('div',{staticClass:"box-intro"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('br'),_c('span',{staticClass:"intro"},[_vm._v(_vm._s(item.intro))])])])}),0)]),_vm._m(7),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-left"},[_c('span',{staticClass:"banner-sub-title"},[_vm._v("电销外呼防封电话")]),_c('br'),_c('span',{staticClass:"banner-sub-title1"},[_vm._v("电销助力神器，高频外呼不封号")]),_c('br'),_c('div',{staticClass:"buttom-qrcode"},[_c('div',{staticClass:"app-title"},[_vm._v(" 扫描二维码 立即体验 ")]),_c('div',{staticClass:"buttom-ytkios"},[_c('img',{attrs:{"src":require("../assets/phone_qr.png"),"width":"100px","height":"100px"}}),_c('br'),_c('span',{staticClass:"buttom-ytkios-text"},[_vm._v("IOS")])]),_c('div',{staticClass:"buttom-ytkandroid"},[_c('img',{attrs:{"src":require("../assets/phone_qr.png"),"width":"100px","height":"100px"}}),_c('br'),_c('span',{staticClass:"buttom-ytkandroid-text"},[_vm._v("Android")])])])]),_c('div',{staticClass:"banner-right"},[_c('img',{attrs:{"src":require("../assets/img/phone_m5.jpg"),"width":"219px","height":"430px"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"quest"},[_c('div',{staticClass:"quest-title"},[_c('span',{staticClass:"quest-title-text"},[_vm._v("做销售，你是否还在因为这些问题影响业绩地提升")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('div',{staticClass:"quest-sub-title"},[_vm._v(" 易拓客致力于帮助客户从海星数据中发现并提炼大量精准化、价值化的销售线索;并实现对线索的快速触达，精准快遨的锁定客户;完成SaaS的销售线索从获取、跟进、触达、转化到留存的整个生命周期的管理。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quest-div"},[_c('div',{staticClass:"quest-content"},[_c('div',{staticClass:"phone-title"},[_vm._v(" 电销外呼防封电话 ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-l"},[_c('img',{attrs:{"src":require("../assets/img/ic-call.png")}})]),_c('div',{staticClass:"content-r"},[_c('ul',[_c('li',[_vm._v(" 1、本机号码拨打对方号码时通过中间号码进行连接，是AxB(外显A)模式透拨回传;主叫拨打的为同一个号码，不符合运营商封卡标准。 ")]),_c('li',[_vm._v(" 2、对方来电号码为您手机卡真实号码，客户漏接电话时可随时回拨，用户无需新增SIM卡，使用原手机卡，下载app登陆账号就可以使用。 ")]),_c('li',[_vm._v(" 3、通话不受限,保证服务可靠性;接通率高达90%;基于运营商网络通话质量好，支持大容量，高并发。 ")]),_c('li',[_vm._v(" 4、客户资源一键导入待呼名单，节省大量时间，专注于外呼销售，导入方式有手机通讯录导入、电脑Excel导入等。 ")]),_c('li',[_vm._v(" 5、客户资料数字化管理，不易遗漏或丢失;自定义客户标签，帮助销售创建精准客户画像，实现从“线索—客户—成单—维护”的客户全生命周期管理过程。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage-title"},[_c('span',{staticClass:"title"},[_vm._v("产品优势")]),_c('br'),_c('div',{staticClass:"server-border"}),_c('br'),_c('span',{staticClass:"sub-title"},[_vm._v("优质产品提供优质服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scenes-title"},[_c('span',{staticClass:"scenes-title-text"},[_vm._v("适用场景")]),_c('br'),_c('div',{staticClass:"server-border"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scenes-content-r"},[_c('img',{attrs:{"src":require("../assets/img/dx_img.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"industry-title"},[_c('span',{staticClass:"industry-title-text"},[_vm._v("适用行业")]),_c('br'),_c('span',{staticClass:"industry-subtitle-text"},[_vm._v("电销外呼适用于各种行业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process"},[_c('div',{staticClass:"process-title"},[_vm._v(" 电销外呼防封电话使用流程 "),_c('div',{staticClass:"msg-border"})]),_c('div',{staticClass:"process-content"},[_c('ul',{staticClass:"process-ul"},[_c('li',{staticClass:"process-ul-li"},[_c('img',{attrs:{"src":require("../assets/img/yx_1.png"),"align":"absmiddle"}}),_c('span',{staticClass:"sub-title"},[_vm._v("注册、完善信息")])]),_c('li',{staticClass:"process-ul-li-icon"},[_c('img',{attrs:{"src":require("../assets/img/yx_arrow.png")}})]),_c('li',{staticClass:"process-ul-li"},[_c('img',{attrs:{"src":require("../assets/img/yx_2.png"),"align":"absmiddle"}}),_c('span',{staticClass:"sub-title"},[_vm._v("等待信息审核")])]),_c('li',{staticClass:"process-ul-li-icon"},[_c('img',{attrs:{"src":require("../assets/img/yx_arrow.png")}})]),_c('li',{staticClass:"process-ul-li"},[_c('img',{attrs:{"src":require("../assets/img/yx_3.png"),"align":"absmiddle"}}),_c('span',{staticClass:"sub-title"},[_vm._v("开通套餐")])]),_c('li',{staticClass:"process-ul-li-icon"},[_c('img',{attrs:{"src":require("../assets/img/yx_arrow.png")}})]),_c('li',{staticClass:"process-ul-li"},[_c('img',{attrs:{"src":require("../assets/img/yx_4.png"),"align":"absmiddle"}}),_c('span',{staticClass:"sub-title"},[_vm._v("拨打使用")])]),_c('li',{staticClass:"process-ul-li-icon"},[_c('img',{attrs:{"src":require("../assets/img/yx_arrow.png")}})]),_c('li',{staticClass:"process-ul-li large-text"},[_c('img',{attrs:{"src":require("../assets/img/yx_5.png"),"align":"absmiddle"}}),_c('span',{staticClass:"sub-title"},[_vm._v("如有问题，咨询客服")])])])])])
}]

export { render, staticRenderFns }