<template>
	<div class="container">
	 <Header :flag="flag" />	
	 <div class="banner">
		 <div class="banner-content">
			 <div class="banner-left">
				 <span class="banner-sub-title">电销外呼防封电话</span>
				 <br/>
				 <span class="banner-sub-title1">电销助力神器，高频外呼不封号</span>
				 <br/>
				 <div class="buttom-qrcode">
					 <div class="app-title">
					 	扫描二维码 立即体验
					 </div>
				 	<div class="buttom-ytkios">
				 		<img src="../assets/phone_qr.png" width="100px" height="100px" />
				 		<br>
				 		<span class="buttom-ytkios-text">IOS</span>
				 	</div>
				 	<div class="buttom-ytkandroid">
				 		<img src="../assets/phone_qr.png" width="100px" height="100px" />
				 		<br>
				 		<span class="buttom-ytkandroid-text">Android</span>
				 	</div>
				 </div>
			 </div>
			 <div class="banner-right">
				<img src="../assets/img/phone_m5.jpg" width="219px" height="430px" />			
			 </div>
		 </div>
	 </div>
	<div class="main">
		 <div class="quest">
			 <div class="quest-title">
				<span class="quest-title-text">做销售，你是否还在因为这些问题影响业绩地提升</span> 
				<br/>
				<div class="server-border"></div>
			 </div>
			 <div class="quest-sub-title">
				 易拓客致力于帮助客户从海星数据中发现并提炼大量精准化、价值化的销售线索;并实现对线索的快速触达，精准快遨的锁定客户;完成SaaS的销售线索从获取、跟进、触达、转化到留存的整个生命周期的管理。
			 </div>
		 </div>
	</div>	
	<div class="quest-div">
		<div class="quest-content">
			<div class="phone-title">
				电销外呼防封电话
			</div>
			<div class="content">
				<div class="content-l">
					<img src="../assets/img/ic-call.png" />
				</div>
				<div class="content-r">
					<ul>
						<li>
							1、本机号码拨打对方号码时通过中间号码进行连接，是AxB(外显A)模式透拨回传;主叫拨打的为同一个号码，不符合运营商封卡标准。
						</li>
						<li>
							2、对方来电号码为您手机卡真实号码，客户漏接电话时可随时回拨，用户无需新增SIM卡，使用原手机卡，下载app登陆账号就可以使用。
						</li>
						<li>
							3、通话不受限,保证服务可靠性;接通率高达90%;基于运营商网络通话质量好，支持大容量，高并发。
						</li>
						<li>
							4、客户资源一键导入待呼名单，节省大量时间，专注于外呼销售，导入方式有手机通讯录导入、电脑Excel导入等。
						</li>
						<li>
							5、客户资料数字化管理，不易遗漏或丢失;自定义客户标签，帮助销售创建精准客户画像，实现从“线索—客户—成单—维护”的客户全生命周期管理过程。
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
		
	<div class="advantage">
		<div class="advantage-title">
			<span class="title">产品优势</span>
			<br/>
			<div class="server-border"></div>
			<br/>
			<span class="sub-title">优质产品提供优质服务</span>
		</div>
		<div class="advantage-content">
			<div class="advantage-box" v-for="item in advantages">
				<div class="box-icon"><img :src="item.image"/></div>
				<div class="box-intro">
					<span class="title">{{item.title}}</span>
					<br/>
					<span class="intro">{{item.subTitle}}</span>
				</div>
			</div>
		</div>
	</div>
	 
	<div class="scenes">
		<div class="scenes-title">
			<span class="scenes-title-text">适用场景</span>
			<br/>
			<div class="server-border"></div>
		</div>
		<div class="scenes-content">
			<div class="scenes-content-l">
				<div class="scenes-box" v-for="item in scenes">
					<div class="box-content">
						<span class="scenes-title">{{item.title}}</span>
						<br/>
						<span class="scenes-text">
							{{item.subTitle}}
						</span>
					</div>
				</div>
			</div>
			<div class="scenes-content-r">
				<img src="../assets/img/dx_img.png" />
			</div>
		</div>
	</div>
		
	<div class="industry">
		<div class="industry-title">
			<span class="industry-title-text">适用行业</span>
			<br/>
			<span class="industry-subtitle-text">电销外呼适用于各种行业</span>
		</div>
		<div class="industry-content">
			<div class="industry-box" v-for="item in industry">
				<div class="box-icon" v-if="item.image!=null && item.image !=''"><img :src="item.image"/></div>
				<div class="box-intro">
					<span class="title">{{item.title}}</span>
					<br/>
					<span class="intro">{{item.intro}}</span>
				</div>
			</div>
		</div>
	</div>
		
	<div class="process">
		<div class="process-title">
			电销外呼防封电话使用流程
			<div class="msg-border"></div>
		</div>
		<div class="process-content">
			<ul class="process-ul">
				<li class="process-ul-li"><img src="../assets/img/yx_1.png" align="absmiddle" />
				<span class="sub-title">注册、完善信息</span>
				</li>
				<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
				<li class="process-ul-li"><img src="../assets/img/yx_2.png"  align="absmiddle"/>
				<span class="sub-title">等待信息审核</span>
				</li>
				<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
				<li class="process-ul-li"><img src="../assets/img/yx_3.png"  align="absmiddle"/>
				<span class="sub-title">开通套餐</span>
				</li>
				<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
				<li class="process-ul-li"><img src="../assets/img/yx_4.png"  align="absmiddle"/>
				<span class="sub-title">拨打使用</span>
				</li>
				<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
				<li class="process-ul-li large-text"><img src="../assets/img/yx_5.png"  align="absmiddle"/>
				<span class="sub-title ">如有问题，咨询客服</span>
				</li>
			</ul>
		</div>
	</div>
	 <Footer />
	</div>	
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '电销外呼防封电话机器人-电话呼叫系统-智能ip电话-虚拟小号-防封电话-电销外呼-[蓝莓科技触达]',
			meta: [
			  {
				name: 'keywords',
				content: '电销外呼防封电话，电销机器人，电话呼叫系统，智能ip电话，外呼触达，语音机器人，智能外呼，硬件外呼，云端外呼，电销外呼，电销卡'
			  },
			  {
				name: 'description',
				content: '外呼触达整合AXB外呼线路，回拨线路，电销机器人、智能电话防封呼叫系统，无需硬件，云端完成外呼，基于AI人工语义理解，加上短信、邮件等多种触达渠道，大幅提高线索有效率。'
			  }
			]
		},
		name: 'Phone',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'solution',
				phoneSales:[
					{
						content:"本机号码拨打对方号码时通过中间号码进行连接，是A x B(外显A)模式透拨回传；主叫拨打的为同一个号码，不符合运营商封卡标准。"
					},
					{
						content:"对方来电号码为您手机卡真实号码，客户漏接电话时可随时回拨，用户无需新增SIM卡，使用原手机卡，下载app登陆账号就可以使用。"
					},
					{
						content:"通话不受限，保证服务可靠性；接通率高达90%；基于运营商网络通话质量好，支持大容量，高并发。"
					},
					{
						content:"客户资源一键导入待呼名单，节省大量时间，专注于外呼销售，导入方式有手机通讯录导入、电脑Excel导入等。"
					},
					{
						content:"客户资料数字化管理，不易遗漏或丢失；自定义客户标签，帮助销售创建精准客户画像，实现从“线索—客户—成单—维护”的客户全生命周期管理过程。"
					}
				],
				advantages:[
					{
						image:require('@/assets/img/dx_1.png'),
						title:"简单易用",
						subTitle:"安装方式方便快捷，操作方式简单易上手"
					},
					{
						image:require('@/assets/img/dx_2.png'),
						title:"稳定可靠",
						subTitle:"支持4G全网通和SIP固话，实现通信信号无干扰，音质清晰稳定"
					},
					{
						image:require('@/assets/img/dx_3.png'),
						title:"高频防封",
						subTitle:"支持拨打次数不受限，支持主叫号码拨打中间号，避开运营商封卡标准"
					},
					{
						image:require('@/assets/img/dx_4.png'),
						title:"接通率高",
						subTitle:"支持显示本机真实号码，实现拨号接通率高，支持客户漏接进行回拨"
					}
				],
				scenes:[
					{
						title:"传统营销方式效率低",
						subTitle:"中小企业传统的上门推广、发传单的销售模式，受到地域性、人力的限制，很难在短期内快速的挖掘意向客户"
					},
					{
						title:"快速筛选目标客户",
						subTitle:"电销外呼防封电话系统，为中小企业提供高效的电话营销服务，告别低效的销售方式，用户通过外呼系统，可以方便的拨打电话、快速的筛选客户线索，挖掘有价值的意向客户，跟进客户并创造高价值客户"
					},
					{
						title:"高效管理销售工作",
						subTitle:"管理者也通过外呼系统，方便的管理每一个销售的工作，从而不断优化、提升销售的工作"
					}
				],
				industry:[]
			}
		},
		created() {
			this.getIndustry();
		},
		methods:{
			getIndustry(){
				this.$http.getIndustryList({type:1}).then(res => {
					if(res.data!=null){
						this.industry = res.data;
					}
				});
			}
		}
	}	
</script>

<style scoped lang="less">
 .banner{
	 padding-top: 80px;
	 height: 450px;
	 background-image: url(https://file.lanmeihulian.com/banner_2.png);
	 background-repeat: no-repeat;
	 background-size:100% 100%;
	 .banner-content{
		 display: flex;
		 display: -webkit-flex;
		 flex-direction:row;
		 width: 1200px;
		 margin: auto;
		 .banner-left{
		 	 width: 900px;
			 text-align: center;
			 .banner-sub-title{
				 display: inline-block;
				 margin-top: 80px;
				 font-size: 42px;
				 font-weight: 600;
				 color: white;
			 }
			 
			 .banner-sub-title1{
				display: inline-block;
				margin-top: 20px;
				font-size: 24px;
				font-weight: 600;
				color: white; 
			 }
			 .buttom-qrcode{
				width: 500px;
				margin: auto;
			 	margin-top: 50px;
			 	height: 100px;
			 	text-align: center;
			 	display: flex;
			 	display: -webkit-flex;
			 	flex-direction:row;
			 	flex-wrap:wrap;
			 	justify-content:center;
				.app-title{
					display: inline-block;
					margin-top: 20px;
					width: 100px;
					font-size: 16px;
					color: #fff;
					text-align: center;
				}
			 	.buttom-ytkios{
			 		width: 120px;
			 		.buttom-ytkios-text{
			 			display: inline-block;
			 			margin-top: 10px;
			 			font-size: 20px;
			 			color: #fff;
			 			text-align: center;
			 		}
			 	}
			 	.buttom-ytkandroid{
			 		width: 120px;
			 		.buttom-ytkandroid-text{
			 			display: inline-block;
			 			margin-top: 10px;
			 			font-size: 20px;
			 			color: #fff;
			 			text-align: center;
			 		}
			 	}
			 }
		 }
		 .banner-right{
			 text-align: center;
		 }
	 }
 }
.main{
	width: 1200px;
	margin: auto;
	.quest{
		margin-top: 72px;
		height: 160px;
		.quest-title{
			text-align: center;
			.quest-title-text{
				font-size: 36px;
				font-weight: 600;
				line-height: 80px;
				color: #131313;
			}
			.server-border{
				margin-top: 32px;
				width: 100px;
				background-color: rgba(44,176,118,0.85);
				height: 8px;
				border-radius: 5px;
				margin: auto;
			}
		}
		.quest-sub-title{
			margin-top: 24px;
			font-size: 18px;
			color: #666666;
			text-align: left;
			line-height: 30px;
		}
	}
}
.quest-div{
	height: 1117px;
	max-width: 1920px;
	margin: auto;
	// background-color: #252525;
	background-image: url(../assets/img/dx_bg.png);
	background-repeat: no-repeat;
	.quest-content{
		height: 1200px;
		width: 1200px;
		margin: auto;
		margin-top: 60px;
		.phone-title{
			padding-top: 400px;
			text-align: center;
			font-size: 40px;
			font-weight: 600;
			line-height: 80px;
			color: white;
		}
		.content{
			margin-top: 40px;
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			justify-content:space-between;
			.content-l{
				
			}
			.content-r{
				margin-top: 50px;
				width: 500px;
				height: 350px;
				color: white;
				ul li{
					height: 80px;
				}
			}
		}
	}
}	
.advantage{
	margin-top: 65px;
	.advantage-title{
		text-align: center;
		.title{
			font-size: 36px;
			font-weight: 600;
			line-height: 80px;
			color: #131313;
		}
		.server-border{
			margin-top: 32px;
			width: 100px;
			background-color: rgba(44,176,118,0.85);
			height: 8px;
			border-radius: 5px;
			margin: auto;
		}
		.sub-title{
			font-size: 24px;
			line-height: 50px;
		}
	}
	.advantage-content{
		margin-top: 40px;
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.advantage-box{
			width: 300px;
			height: 350px;
			margin: 10px 20px 10px 10px;
			padding-bottom: 30px;
			text-align: center;
			background-color: #F2F2F2;
			.box-icon{
				height: 90px;
				padding-top: 80px;
				img{
					width: 80px;
					height: 80px;
				}
			}
			.box-intro{
				.title{
					font-size: 22px;
					font-weight:600;
					line-height: 65px;
				}
				.intro{
					font-size: 18px;
					line-height:30px;
					display: inline-block;
					width: 220px;
				}
			}
		}
		.advantage-box:hover{
			.box-intro{
				.title{
					color: #409EFF;
				}
			}
		}
	}
}
.scenes{
	width: 1200px;
	margin: auto;
	margin-top: 73px;
	.scenes-title{
		text-align: center;
		.scenes-title-text{
			text-align: center;
			font-size: 36px;
			font-weight: 600;
			line-height: 80px;
			color: #131313;
		}	
		.server-border{
			margin-top: 32px;
			width: 100px;
			background-color: rgba(44,176,118,0.85);
			height: 8px;
			border-radius: 5px;
			margin: auto;
		}
	}
	.scenes-content{
		width: 1200px;
		margin: auto;
		margin-top: 30px;
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.scenes-content-l{
			width: 700px;
			.scenes-box{
				width: 550px;
				height: 200px;
				.box-content{
					margin: 10px;
					.scenes-title{
						display: inline-block;
						font-size: 26px;
						font-weight: 600;
						line-height: 50px;
						color: #131313;
					}
					.scenes-text{
						display: inline-block;
						margin-top: 20px;
						font-size: 18px;
						line-height: 30px;
						color: #131313;
					}
				}
			}
			
		}
	}
}
.industry{
	width: 1200px;
	margin: auto;
	margin-top: 126px;
	.industry-title{
		text-align: center;
		.industry-title-text{
			display: inline-block;
			text-align: center;
			font-size: 40px;
			font-weight: 600;
			line-height: 50px;
		}
		.industry-subtitle-text{
			display: inline-block;
			text-align: center;
			font-size: 20px;
			line-height: 50px;
			color: #666666;
		}
	}
	.industry-content{
		margin-top: 30px;
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		.industry-box{
			height: 330px;
			width: 280px;
			margin: 10px 10px 10px 10px;
			padding-top: 20px;
			text-align: center;
			background-color: #F2F2F2;
			.box-icon{
				height: 120px;
				width: 280px;
				margin: auto;
				display:table-cell;
				vertical-align: bottom;
				text-align: center;
				img{
					width: 50px;
					height: 50px;
				}
			}
			.box-intro{
				margin-top: 20px;
				.title{
					font-size: 24px;
					font-weight:600;
					line-height: 65px;
				}
				.intro{
					font-size: 16px;
					line-height:30px;
					display: inline-block;
					width: 240px;
					padding-bottom: 40px;
				}
			}
		}	
		.industry-box:hover{
			.box-intro{
				.title{
					color: #409EFF;
				}
			}
		}
	}	
}
.process{
	width: 1200px;
	margin: auto;
	margin-top: 84px;
	height: 350px;
	.process-title{
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		line-height: 80px;
		.msg-border{
			margin: auto;
			height: 10px;
			width: 100px;
			background-color: rgba(44,176,118,0.85);
			border-radius: 5px;
		}
	}
	.process-content{
		.process-ul{
			margin-top: 60px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: center;
			.process-ul-li{
				width: 250px;
				height: 50px;
				padding-top: 28px;
				text-align: center;
				background-color: #F2F2F2;
				.sub-title{
					padding-left: 20px;
					font-size: 20px;
				}
			}
			.process-ul-li-icon{
				width: 40px;
				height: 40px;
				padding-top: 20px;
				text-align: center;
			}
			.large-text{
				width: 300px;
			}
		}
	}
}
	
</style>
